import messageTipVue from './index.vue';
const MessageTip = {};
MessageTip.install = function (Vue, options) {
  // console.log(options, '-----MessageTip-----options');
  const MessageTipInstance = Vue.extend(messageTipVue);
  let currentMsg;
  const initInstance = () => {
    currentMsg = new MessageTipInstance();
    let msgBoxEl = currentMsg.$mount().$el;
    document.body.appendChild(msgBoxEl);
  };
  Vue.prototype.$msgTip = {
    showTip(options) {
      if (!currentMsg) {
        initInstance();
      }
      if (typeof options === 'string') {
        currentMsg.content = options;
      } else if (typeof options === 'object') {
        Object.assign(currentMsg, options);
      }
      return currentMsg;
    }
  };
};
export default MessageTip;
