<template>
  <div class="cus_dialog_cmps">
    <div class="cus_dialog_mask" v-show="dialogVisible"></div>
    <el-dialog title="身份验证" :visible.sync="dialogVisible" width="400px" :modal="false">
      <div style="color: red; font-size: 16px; margin-top: -15px; padding: 10px">请使用电子税局APP扫描下方二维码，进行身份验证</div>
      <el-image :src="qrUrl" style="height: 200px; width: 200px; padding: 10px" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">已认证完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {qrCodeLogin} from '@/service/rpa/rpa-auth';

export default {
  data() {
    return {
      qrUrl: '',
      dialogVisible: false
    };
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    content(val) {
      val &&
        qrCodeLogin(val).then((res) => {
          const { data, success, message } = res;
          if (!success) return this.toast(message, 'warning');
          this.$nextTick(() => {
            this.qrUrl = data;
            this.dialogVisible = true;
          });
        });
    }
  },
  mounted() {},
  methods: {
    show() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.cus_dialog_cmps {
  position: relative;

  .cus_dialog_mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.5;
    background: #000;
    z-index: 2000;
  }
}
</style>
