<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="scss">
html {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
}

/*.scrollbar {*/
/*    scrollbar-width: none; !* firefox *!*/
/*    -ms-overflow-style: none; !* IE 10+ *!*/
/*}*/
/*::-webkit-scrollbar {*/
/*    display: none; !* Chrome Safari *!*/
/*}*/

body {
  height: 100vh;
  margin: 0;
  min-width: 1280px;
  overflow-y: auto;
}
</style>
