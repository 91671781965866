// 主路由页面
// export const mainPage = [
//   {
//     path: "/homePage",
//     name: "首页",
//     component: () => import("@/views/homePage/homePage.vue"),
//     meta: {code: "HP"},
//   },
// ]
// 分享结算单
export const shareSettlement = [
  {
    path: "/share-settlement-verify-pwd",
    name: "验证密码",
    component: () => import("@/views/share-settlement/VerifyPwd"),
    meta: { isNotTab: true },
  },
  {
    path: "/share-settlement-list",
    name: "列表",
    component: () => import("@/views/share-settlement/List"),
    meta: { isNotTab: true },
  },
  {
    path: "/share-settlement-detail",
    name: "明细比对",
    component: () => import("@/views/share-settlement/Detail"),
    meta: { isNotTab: true },
  },
];

// 个人票夹
// const personWalt = {
//   path: "/person-wallet",
//   name: "OFD收票",
//   component: () => import("@/views/person-wallet/PersonWallet"),
//   meta: {
//     code: "RP",
//     disabled: false,
//     menu: true,
//     icon: require("@/assets/navMenu/person.png"),
//   },
// };

// 开票模块模块 RB
const invoiceOpen = {
  path: "/open",
  name: "销项开票",
  component: () => import("@/views/EmptyLayout"),
  meta: {
    code: "RB",
    disabled: true,
    menu: true,
    // icon: require("@/assets/navMenu/system.png"),
  },
  children: [
    {
      path: "/open/issue",
      name: "待开发票",
      meta: {
        code: "RB",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/invoice.png"),
      },
      component: () =>
          import("@/views/invoice-open/invoice-issue/InvoiceIssue"),
      children: [
        {
          path: "/open/issue/red-notice",
          name: "红色通知单",
          component: () =>
              import("@/views/invoice-open/invoice-red/BillingRedNotice"),
          meta: { code: "RB" },
          children: [
            {
              path: "/open/issue/red-notice/form",
              name: "红字增值税专用发票信息表",
              component: () => import("@/views/declare/tmpl/History"),
              meta: { code: "RB" },
            },
          ],
        },
      ],
    },
    {
      path: "/electron/invoice",
      name: "全电开票",
      meta: {
        code: "EI",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/electronInvoice.png"),
      },
      component: () =>
          import("@/views/invoice-open/invoice-issue/ElectronInvoiceIssue"),
    },
    {
      path: "/open/manual",
      name: "手工开票",
      meta: {
        code: "RB-M06",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/invoice.png"),
      },
      component: () =>
        import("@/views/invoice-open/manual-invoice/ManualInvoice"),
    },
    {
      path: "/open/quick-invoice",
      name: "扫码开票",
      meta: {
        code: "RB-M11",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/scan.png"),
      },
      component: () => import("@/views/invoice-open/quick-invoice/index"),
    },
    {
      path: "/open/red-invoice",
      name: "红票开具",
      meta: {
        code: "RB-M10",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/redinvoice.png"),
      },
      component: () =>
          import(
              "@/views/invoice-open/invoice-red/red-invoice-open/BillingRedInvoiceIssue"
              ),
    },
    // {
    //   path: '/open/difference',
    //   name: '开票差异表',
    //   meta: {
    //     code: 'RB',
    //     disabled: false,
    //     menu: true
    //   },
    //   component: () => import('@/views/invoice-open/invoice-difference/DifferenceTable')
    // },
    {
      path: "/open/differenceDetail",
      name: "开票差异详情表",
      meta: {
        code: "RB",
        disabled: false,
        menu: false,
      },
      component: () =>
          import("@/views/invoice-open/invoice-difference/DifferenceDetail"),
    },
    {
      path: "/open/express",
      name: "发票邮寄",
      component: () =>
          import("@/views/invoice-open/invoice-express/InvoiceExpressList"),
      meta: {
        code: "RB-M02",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/post.png"),
      },
    },
    {
      path: "/open/billing-config",
      name: "开票配置",
      meta: {
        code: "RB-M03",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/config.png"),
      },
      component: () => import("@/views/output/invoiceOpenConfiguration"),
    },
    {
      path: "/open/red-notice",
      name: "红色通知单",
      meta: {
        code: "RB",
        disabled: false,
        menu: false,
      },
      component: () =>
          import("@/views/invoice-open/invoice-red/BillingRedNotice"),
      children: [
        {
          path: "/open/red-notice/form",
          name: "红字增值税专用发票信息表",
          meta: {
            code: "RB",
          },
          component: () =>
              import("@/views/invoice-open/invoice-red/BillingRedNoticeForm"),
        },
      ],
    },
    {
      path: "/rpa/auth",
      name: "数电账户",
      meta: {
        code: "RB-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/account.png"),
      },
      component: () => import("@/views/rpa/e-account/v1/index"),
    },
    {
      path: "/open/billing-statistics",
      name: "首页",
      // name: "开票统计",
      meta: {
        code: "BS-001",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/statistics.png"),
      },
      component: () => import("@/views/invoice-open/billing-statistics/index"),
    },
    {
      path: "/sal/invoicing-trade",
      name: "订单管理",
      meta: {
        code: "om-001",
        disabled: false,

        menu: true,
        icon: require("@/assets/navMenu/order.png"),
      },
      component: () => import("@/views/sal/invoicing-trade/index"),
    },
    {
      path: "/sal/repaire",
      name: "发票修复",
      meta: {
        code: "RB-M06",
        disabled: false,

        menu: true,
        icon: require("@/assets/navMenu/repaire.png"),
      },
      component: () => import("@/views/sal/repaire/index"),
    },
    {
      path: "/open/statistics",
      name: "发票数据统计",
      component: () => import("@/views/statistics/index"),
      meta: {
        code: "RI-005",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/incomeinvoice.png"),
      },
    },
    {
      path: "/pool/sales",
      name: "销项查询",
      component: () => import("@/views/invoice-pool/SalesInvoicePool"),
      meta: {
        code: "RI-M02",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/incomeinvoice.png"),
      },
    },
    // {
    //     path: '/rpa/e-account',
    //     name: '数电账户',
    //     meta: {
    //         code: 'RB-M05',
    //         disabled: false,
    //         menu: true
    //     },
    //   component: () => import('@/views/rpa/e-account/v1/index')
    // },
    // {
    //   path: '/open/account-management',
    //   name: '全电账号管理',
    //   meta: {
    //     code: 'AAM',
    //     disabled: false,
    //     menu: true
    //   },
    //   component: () => import('@/views/invoice-open/allElectric-accountManagement')
    // },
    // {
    //   path: '/open/scanCodes',
    //   name: '扫码开票设置',
    //   meta: {
    //     code: 'SC',
    //     disabled: false,
    //     menu: true
    //   },
    //   component: () => import('@/views/invoice-open/scanCodes')
    // }
  ],
};

// 全电开票模块 EI
// const electron = {
//   path: "/electron",
//   name: "全电管理",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "EI",
//     disabled: true,
//     menu: true,

//   },
//   children: [
//     {
//       path: "/electron/invoice",
//       name: "全电开票",
//       meta: {
//         code: "EI",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/electronInvoice.png"),
//       },
//       component: () =>
//           import("@/views/invoice-open/invoice-issue/ElectronInvoiceIssue"),
//     },
//   ],
// };

// 收票中心模块 RC
const invoiceCenter = {
  path: "/collect",
  name: "进项管理",
  meta: {
    code: "RC",
    menu: true,
    disabled: true,

  },
  component: () => import("@/views/EmptyLayout"),
  children: [
    {
      path: "/collect/mylist",
      name: "我的票夹",
      component: () => import("@/views/invoice-center/MyIncomeInvoicePool"),
      meta: {
        code: "RC-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/outcomeinvoice.png"),
      },
      children: [
        {
          path: "/collect/mylist/upload",
          name: "本地收票",
          component: () => import("@/views/invoice-center/LocalCollectInvoice"),
          meta: {
            disabled: false,
            code: "RC-F03",
          },
        },
      ],
    },
    {
      path: "/collect/enterpriselist",
      name: "企业票池",
      component: () => import("@/views/invoice-center/EnterpriseIncomeInvoicePool"),
      meta: {
        code: "RC-M05",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/outcomeinvoice.png"),

      },
    },
    {
      path: "/collect/recognize/failure/record",
      name: "采集记录",//识别失败记录
      meta: {
        code: "RC",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/record.png"),
      },
      component: () => import("@/views/invoice-center/RecognizeFailureRecord"),
    },
    {
      path: "/collect/recognize/invoice/setting",
      name: "收票设置",
      meta: {
        code: "IS",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/inspection.png"),
      },
      component: () => import("@/views/invoice-center/InvoiceSetting"),
    },
    // {
    //   path: "/system/mailbox-settings",
    //   name: "收票邮箱设置",
    //   component: () => import("@/views/system/mailboxSettings"),
    //   meta: {
    //     code: "RS-YXSZ",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/mail.png"),
    //   },
    // },

    // {
    //   path: "/collect/list",
    //   name: "增值税票",
    //   meta: {
    //     code: "RC",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/addtax.png"),
    //   },
    //   component: () => import("@/views/invoice-center/InvoiceCenterList"),
    //   children: [
    //     {
    //       path: "/collect/list/scanning/gun",
    //       name: "扫码枪收票",
    //       component: () =>
    //           import("@/views/invoice-center/ScanningGunCollectInvoice"),
    //       meta: {
    //         disabled: false,
    //         code: "RC-F01",
    //       },
    //     },
    //     {
    //       path: "/collect/list/scanner",
    //       name: "扫描仪收票",
    //       component: () =>
    //           import("@/views/invoice-center/ScannerCollectInvoice"),
    //       meta: {
    //         disabled: false,
    //         code: "RC-F02",
    //       },
    //     },
    //     {
    //       path: "/collect/list/upload",
    //       name: "本地收票",
    //       component: () => import("@/views/invoice-center/LocalCollectInvoice"),
    //       meta: {
    //         disabled: false,
    //         code: "RC-F03",
    //       },
    //     },
    //   ],
    // },
    // {
    //   path: "/collect/other/invoice",
    //   name: "其他发票",
    //   meta: {
    //     code: "RC",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/otherinvoice.png"),
    //   },
    //   component: () => import("@/views/invoice-center/OtherInvoice"),
    // },

    // {
    //   path: "/pool/income",
    //   name: "进项发票", //（进项发票凭证）
    //   component: () => import("@/views/invoice-pool/IncomeInvoicePool"),
    //   meta: {
    //     code: "RI-M01",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/outcomeinvoice.png"),

    //   },
    // },
    
    {
      path: "/deduction/certification",
      name: "进项认证信息",
      component: () => import("@/views/income-selection/Certification"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/auth.png"),
      },
    },
    {
      path: "/deduction/preSelection",
      name: "进项发票预勾选",
      component: () => import("@/views/income-selection/PreSelection"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductions.png"),
      },
    },
    {
      path: "/deduction/selection",
      name: "进项发票勾选抵扣",
      component: () => import("@/views/income-selection/Selection"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductions.png"),
      },
    },
    {
      path: "/deduction/incomeDeduction",
      name: "进项发票抵扣统计",
      component: () => import("@/views/income-deduction/IncomeDeduction"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductionstatistics.png"),
      },
    },
    {
      path: "/deduction/passengerIncomeDeduction",
      name: "客运服务抵扣",
      component: () => import("@/views/income-deduction/PassengerIncomeDeduction"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductionstatistics.png"),
      },
    },
    {
      path: "/deduction/passengerIncomeDeductionStatistics",
      name: "客运服务抵扣统计",
      component: () => import("@/views/income-deduction/PassengerIncomeDeductionStatistics"),
      meta: {
        code: "RD-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductionstatistics.png"),
      },
    },
    {
      path: "/collect/invoiceFiling",
      name: "发票归档管理",
      component: () => import("@/views/invoice-center/InvoiceFiling"),
      meta: {
        code: "RC-M05",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/deductionstatistics.png"),
      },
    },
  ],
};

// 凭证管理 VM
const voucherManage = {
  path: "/voucher",
  name: "凭证管理",
  component: () => import("@/views/EmptyLayout"),
  meta: {
    code: "VM",
    disabled: true,
    menu: true,

  },
  children: [
    {
      path: "/voucher/list",
      name: "凭证列表",
      meta: {
        code: "VM-LS",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/voucher.png"),
      },
      component: () => import("@/views/voucher-manage/list"),
    },
    {
      path: "/voucher/template",
      name: "凭证模板",
      meta: {
        code: "VM-TP",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/template.png"),
      },
      component: () => import("@/views/voucher-manage/template"),
    },
    {
      path: "/voucher/subject",
      name: "科目管理",
      meta: {
        code: "VM-KM",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/subject.png"),
      },
      component: () => import("@/views/voucher-manage/subject"),
    },
    {
      path: "/voucher/items",
      name: "项目管理",
      meta: {
        code: "VM-XM",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/project.png"),
      },
      component: () => import("@/views/voucher-manage/items"),
    },
    {
      path: "/voucher/config",
      name: "凭证配置",
      meta: {
        code: "VM-VC",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/vouchersetting.png"),
      },
      component: () => import("@/views/voucher-manage/config"),
    },
    {
      path: "/entry/list",
      name: "发票入账",
      disabled: false,
      component: () => import("@/views/entry/invoice-entry"),
      meta: { code: "RZ-VAT", disabled: false, menu: true,icon: require("@/assets/navMenu/invoicein.png"), },
    },
    {
      path: "/entry/set",
      name: "入账设置",
      disabled: false,
      component: () => import("@/views/entry/List"),
      meta: { code: "RZ-RZSZ", disabled: false, menu: true,icon: require("@/assets/navMenu/settingin.png"),  },
    },
  ],
};


// 发票中心模块 RI
// const invoicePool = {
//   path: "/pool",
//   name: "我的票池",
//   component: () => import("@/views/InvoicePool"),
//   meta: {
//     code: "RI",
//     menu: true,
//     disabled: true,

//   },
//   children: [
//     {
//       path: "/pool/income",
//       name: "进项发票",
//       component: () => import("@/views/invoice-pool/IncomeInvoicePool"),
//       meta: {
//         code: "RI-M01",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/outcomeinvoice.png"),

//       },
//     },
//     {
//       path: "/pool/sales",
//       name: "进项发票",
//       component: () => import("@/views/invoice-pool/SalesInvoicePool"),
//       meta: {
//         code: "RI-M02",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/incomeinvoice.png"),
//       },
//     },
//   ],
// };


// 抵扣模块 RD
// const deduction = {
//   path: "deduction",
//   name: "抵扣认证",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RD",
//     menu: true,
//     disabled: true,

//   },
//   children: [
//     {
//       path: "/deduction/certification",
//       name: "进项认证信息",
//       component: () => import("@/views/income-selection/Certification"),
//       meta: {
//         code: "RD-M04",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/auth.png"),
//       },
//     },
//     {
//       path: "/deduction/selection",
//       name: "进项发票勾选抵扣",
//       component: () => import("@/views/income-selection/Selection"),
//       meta: {
//         code: "RD-M04",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/deductions.png"),
//       },
//     },
//     {
//       path: "/deduction/incomeDeduction",
//       name: "进项发票抵扣统计",
//       component: () => import("@/views/income-deduction/IncomeDeduction"),
//       meta: {
//         code: "RD-M04",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/deductionstatistics.png"),
//       },
//     },
//   ],
// };

// // 销售协同 RO
// const supplierCooperate = {
//   path: "/supplier",
//   name: "销售协同",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RO",
//     disabled: true,
//     icon: require("@/assets/navMenu/supplier.png"),
//     menu: true,
//   },
//   children: [
//     {
//       path: "/supplier/settlement",
//       name: "结算开票",
//       component: () =>
//         import("@/views/cooperate/settlement/list/SupplierSettlementList"),
//       meta: {
//         code: "RO-M07",
//         disabled: false,
//         menu: true,
//       },
//       children: [
//         {
//           path: "/supplier/settlement/details",
//           name: "结算详情",
//           meta: {
//             code: "RO-M07",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/SettlementDetails"),
//         },
//         {
//           path: "/supplier/settlement/plans",
//           name: "开票详情",
//           meta: {
//             code: "RO-M07",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/InvoicePlans"),
//         },
//         {
//           path: "/supplier/settlement/matching",
//           name: "稽核详情",
//           meta: {
//             code: "RO-M07",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/SettlementMatching"),
//         },

//         {
//           path: "/detailed-comparison",
//           name: "明细比对",
//           component: () =>
//             import("@/views/cooperate/settlement/details/DetailedComparison"),
//           meta: { code: "RO-M07" },
//         },
//         {
//           path: "/supplier/settlement/matching/print-pre-view",
//           name: "打印预览",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M07",
//           },
//           component: () =>
//             import(
//               "@/views/cooperate/settlement/details/components/matching/PrintPreView"
//             ),
//         },
//         {
//           path: "/supplier/collect/upload",
//           name: "本地上传",
//           component: () => import("@/views/invoice-center/LocalCollectInvoice"),
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M07",
//           },
//         },
//         {
//           path: "/supplier/collect/scanner",
//           name: "扫描仪上传",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M07",
//           },
//           component: () =>
//             import("@/views/invoice-center/ScannerCollectInvoice"),
//         },
//         {
//           path: "/supplier/settlement/uploadInvoiceList",
//           name: "交票详情",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M07",
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/components/uploadInvoiceList"),
//         },
//       ],
//     },
//     {
//       path: "/supplier/retailer",
//       name: "需方管理",
//       component: () => import("@/views/cooperate/retailer/Retailer"),
//       meta: {
//         code: "RO-M05",
//         disabled: false,
//         menu: true,
//       },
//     },
//     {
//       path: "/bill/detail",
//       name: "单据详情",
//       component: () => import("@/views/cooperate/sales-comparison/BillDetail"),
//       meta: {
//         code: "RO-M07",
//         disabled: true,
//         menu: false,
//       },
//     },
//   ],
// };

// 销售协同 RO
// const cooSal = {
//   path: "/coo/sal",
//   name: "发票协同",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RO",
//     disabled: true,

//     menu: true,
//   },
//   children: [
//     {
//       path: "/coo/sal/trade",
//       name: "结算开票",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeList.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/settlement.png"),
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/buyer/list",
//       name: "需方管理",
//       component: () => import("@/views/cooperate/retailer/Retailer"),
//       meta: {
//         code: "RO-M05",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/demand.png"),
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/detail",
//       name: "结算详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeDetail.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: false,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/invoicing/detail",
//       name: "开票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementInvoicingDetail.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: false,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/receiving/detail",
//       name: "交票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementReceivingDetail.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: true,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/matching/detail",
//       name: "稽核详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementMatchingDetail.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: false,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/evidence/detail",
//       name: "打印预览",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementEvidenceDetail.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: true,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/receiving/scanner",
//       name: "扫描上传",
//       component: () =>
//           import("@/views/invoice-center/ScannerCollectInvoice.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: true,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/sal/trade/receiving/localup",
//       name: "文件上传",
//       component: () => import("@/views/invoice-center/LocalCollectInvoice.vue"),
//       meta: {
//         code: "RO-M07",
//         disabled: true,
//         menu: false,
//         cooRole: "SS",
//       },
//       props: { cooRole: "SS" },
//     },
//     {
//       path: "/coo/buy/trade",
//       name: "结算收票",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeList.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/collect.png"),
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/seller/list",
//       name: "供方管理",
//       component: () => import("@/views/cooperate/seller-manage/SellerManage"),
//       meta: {
//         code: "RO-M05",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/suppliers.png"),
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/detail",
//       name: "结算详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/invoicing/detail",
//       name: "开票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementInvoicingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/detail",
//       name: "交票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementReceivingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/matching/detail",
//       name: "稽核详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementMatchingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/evidence/detail",
//       name: "打印预览",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementEvidenceDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/scanner",
//       name: "扫描上传",
//       component: () =>
//           import("@/views/invoice-center/ScannerCollectInvoice.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/localup",
//       name: "文件上传",
//       component: () => import("@/views/invoice-center/LocalCollectInvoice.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//   ],
// };

// 采购协同 RO
// const cooBuy = {
//   path: "/coo/buy",
//   name: "采购协同",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RO",
//     disabled: true,

//     menu: true,
//   },
//   children: [
//     {
//       path: "/coo/buy/trade",
//       name: "结算收票",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeList.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/collect.png"),
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/seller/list",
//       name: "供方管理",
//       component: () => import("@/views/cooperate/seller-manage/SellerManage"),
//       meta: {
//         code: "RO-M05",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/suppliers.png"),
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/detail",
//       name: "结算详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementTradeDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/invoicing/detail",
//       name: "开票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementInvoicingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/detail",
//       name: "交票详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementReceivingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/matching/detail",
//       name: "稽核详情",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementMatchingDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/evidence/detail",
//       name: "打印预览",
//       component: () =>
//           import("@/views/cooperate/settlement/SettlementEvidenceDetail.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/scanner",
//       name: "扫描上传",
//       component: () =>
//           import("@/views/invoice-center/ScannerCollectInvoice.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//     {
//       path: "/coo/buy/trade/receiving/localup",
//       name: "文件上传",
//       component: () => import("@/views/invoice-center/LocalCollectInvoice.vue"),
//       meta: {
//         code: "RO-M03",
//         disabled: true,
//         menu: false,
//         cooRole: "DS",
//       },
//       props: { cooRole: "DS" },
//     },
//   ],
// };

// // 采购协同 RO
// const retailerCooperate = {
//   path: "/retailer",
//   name: "采购协同",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RO",
//     disabled: true,
//     icon: require("@/assets/navMenu/retailer.png"),
//     menu: true,
//   },
//   children: [
//     {
//       path: "/retailer/settlement",
//       name: "结算收票",
//       meta: {
//         code: "RO-M03",
//         disabled: false,
//         menu: true,
//       },
//       component: () =>
//         import("@/views/cooperate/settlement/list/SettlementList"),
//       children: [
//         {
//           path: "/retailer/settlement/auditDetail",
//           name: "稽核详情",
//           meta: {
//             code: "RO-M03",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/AuditDetail.vue"),
//         },
//         {
//           path: "/retailer/settlement/details",
//           name: "结算详情",
//           meta: {
//             code: "RO-M04",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/SettlementDetails"),
//         },
//         {
//           path: "/retailer/settlement/viewInvoice",
//           name: "开票详情",
//           meta: {
//             code: "RO-M03",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/InvoicePlans"),
//         },
//         {
//           path: "/retailer/settlement/scanning-gun-receive-invoice",
//           name: "扫码枪扫描",
//           meta: {
//             code: "RO-M03",
//             disabled: false,
//           },
//           component: () =>
//             import(
//               "@/views/cooperate/settlement/scanning-gun-receive-invoice/ScanningGunReceive"
//             ),
//         },
//         {
//           path: "/retailer/settlement/invoice-match",
//           name: "发票管理",
//           component: () =>
//             import("@/views/cooperate/invoice-match/InvoiceMatch"),
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M03",
//           },
//         },
//         {
//           path: "/retailer/settlement/un_matched_invoice",
//           name: "未匹配发票",
//           component: () =>
//             import(
//               "@/views/cooperate/invoice-match/components/UnMatchedInvoiceList"
//             ),
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M03",
//           },
//         },
//         {
//           path: "/retailer/collect/upload",
//           name: "本地上传",
//           component: () => import("@/views/invoice-center/LocalCollectInvoice"),
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M03",
//           },
//         },
//         {
//           path: "/retailer/collect/scanner",
//           name: "扫描仪上传",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M03",
//           },
//           component: () =>
//             import("@/views/invoice-center/ScannerCollectInvoice"),
//         },
//         {
//           path: "/retailer/settlement/matching",
//           name: "收票管理",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M03",
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/SettlementMatching"),
//         },
//         {
//           path: "/retailer/settlement/plans",
//           name: "开票详情",
//           meta: {
//             code: "RO-M03",
//             disabled: false,
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/details/InvoicePlans"),
//         },
//         {
//           path: "/retailer/settlement/uploadInvoiceList",
//           name: "结算交票",
//           meta: {
//             menu: false,
//             disabled: true,
//             code: "RO-M07",
//           },
//           component: () =>
//             import("@/views/cooperate/settlement/components/uploadInvoiceList"),
//         },
//       ],
//     },
//     {
//       path: "/retailer/seller/management",
//       name: "供方管理",
//       component: () => import("@/views/cooperate/seller-manage/SellerManage"),
//       meta: {
//         code: "RO-M05",
//         menu: true,
//       },
//     },
//   ],
// };

// 入账管理
// const entry = {
//   path: "/entry",
//   name: "入账管理",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "RZ",
//     disabled: false,
//     menu: true,
//   },
//   children: [
//     {
//       path: "/entry/list",
//       name: "发票入账",
//       disabled: false,
//       component: () => import("@/views/entry/invoice-entry"),
//       meta: { code: "RZ-VAT", disabled: false, menu: true,icon: require("@/assets/navMenu/invoicein.png"), },
//     },
//     {
//       path: "/entry/set",
//       name: "入账设置",
//       disabled: false,
//       component: () => import("@/views/entry/List"),
//       meta: { code: "RZ-RZSZ", disabled: false, menu: true,icon: require("@/assets/navMenu/settingin.png"),  },
//     },
//   ],
// };

// 纳税申报
// const declare = {
//   path: "/declare",
//   name: "纳税申报",
//   component: () => import("@/views/EmptyLayout"),
//   meta: {
//     code: "DEC",
//     disabled: true,

//     menu: true,
//   },
//   children: [
//     {
//       path: "/declare/ent/tmpl/list",
//       name: "增值税申报",
//       disabled: false,
//       component: () => import("@/views/declare/tmpl/index"),
//       meta: { code: "DEC-TMPL-ENT-QUERY", menu: true ,icon: require("@/assets/navMenu/apply.png"),},
//       children: [
//         {
//           path: "/declare/ent/tmpl/detail",
//           name: "增值税申报详情",
//           component: () => import("@/views/declare/tmpl/Detail"),
//           meta: { code: "DEC-TMPL-ENT-QUERY" },
//           children: [
//             {
//               path: "/declare/tmpl/detail/history",
//               name: "底稿调整记录",
//               component: () => import("@/views/declare/tmpl/History"),
//               meta: { code: "DEC-TMPL-ENT-QUERY" },
//             },
//           ],
//         },
//       ],
//     },
//     {
//       path: "/declare/ent/external/list",
//       name: "外部数据",
//       disabled: false,
//       component: () => import("@/views/declare/external/index"),
//       meta: { code: "DEC-TMPL-ENT-QUERY", menu: true,icon: require("@/assets/navMenu/data.png"), },
//       children: [
//         {
//           path: "/declare/ent/external/detail",
//           name: "增值税申报详情",
//           component: () => import("@/views/declare/external/detail"),
//           meta: { code: "DEC-TMPL-ENT-QUERY" },
//         },
//       ],
//     },
//     {
//       path: "/declare/setting",
//       name: "申报设置",
//       component: () => import("@/views/EmptyLayout"),
//       disabled: false,
//       meta: { code: "DEC-CONFIG", menu: true,icon: require("@/assets/navMenu/applysetting.png"),  },
//       children: [
//         {
//           path: "/declare/data/ent/list",
//           name: "数据模板",
//           disabled: false,
//           component: () => import("@/views/declare/data/Index"),
//           meta: { code: "DEC-DATA-ENT-QUERY", menu: true,icon: require("@/assets/navMenu/datatemplate.png"), },
//         },
//         {
//           path: "/declare/data/ent/detail",
//           name: "详情",
//           disabled: false,
//           component: () => import("@/views/declare/data/Detail"),
//           meta: { code: "DEC-DATA-ENT-QUERY", menu: false },
//         },
//         {
//           path: "/declare/tmpl/tnt/list",
//           name: "底稿模板",
//           disabled: false,
//           component: () =>
//               import("@/views/declare/setting/DeclareTntDeclareTmpl"),
//           meta: { code: "DEC-TEMP-TNT-QUERY", menu: true ,icon: require("@/assets/navMenu/draft.png"),},
//         },
//         {
//           path: "/declare/dict",
//           name: "科目余额表",
//           disabled: false,
//           component: () =>
//               import("@/views/declare/setting/DeclareEntSubjectDict"),
//           meta: { code: "DEC-DICT-QUERY", menu: true,icon: require("@/assets/navMenu/balance.png"), },
//         },
//         {
//           path: "/declare/setting/list",
//           name: "组织申报信息",
//           disabled: false,
//           component: () => import("@/views/declare/setting/DeclareEntSetting"),
//           meta: { code: "DEC-SETTING-QUERY", menu: true,icon: require("@/assets/navMenu/applyinfo.png"), },
//         },
//         {
//           path: "/declare/setting/detail",
//           name: "详情",
//           disabled: false,
//           component: () =>
//               import("@/views/declare/setting/DeclareEntSettingDetail"),
//           meta: { code: "DEC-SETTING-QUERY", menu: false },
//         },
//       ],
//     },
//   ],
// };

// 基础设置模块 RS
const system = {
  path: "/system",
  name: "基础设置",
  component: () => import("@/views/SystemConfig"),
  meta: {
    code: "RS",
    menu: true,
    disabled: true,

  },
  children: [
    // {
    //   path: '/system/logs',
    //   name: '日志管理',
    //   component: () => import('@/views/system/logs'),
    //   meta: {
    //     code: 'LOGS',
    //     disabled: false,
    //     menu: true
    //   }
    // },
    {
      path: "/system/user-manage",
      name: "用户管理",
      component: () => import("@/views/system/user"),
      meta: {
        code: "RS-M02",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/user.png"),
      },
    },
    {
      path: "/system/role-manage",
      name: "岗位管理",
      component: () => import("@/views/system/RoleConfig"),
      meta: {
        code: "RS-M01",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/station.png"),
      },
    },
    {
      path: "/system/organization",
      name: "组织管理",
      component: () => import("@/views/system/organization/list"),
      meta: {
        code: "RS-M03",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/orgnization.png"),
      },
      children: [
        {
          path: "/system/organization/create",
          name: "组织创建",
          component: () => import("@/views/system/organization/create"),
          meta: {
            code: "RS-M03",
            disabled: false,
          },
        },
        {
          path: "/system/organization/details",
          name: "组织详情",
          component: () => import("@/views/system/organization/details"),
          meta: {
            code: "RS-M03",
            disabled: false,
          },
        },
        {
          path: "/system/organization/success",
          name: "提交成功",
          component: () => import("@/views/system/organization/success"),
          meta: {
            code: "RS-M03",
            disabled: false,
          },
        },
        {
          path: "/system/organization/TenantDetails",
          name: "单位信息",
          component: () => import("@/views/system/organization/TenantDetails"),
          meta: {
            code: "RS-M03",
            disabled: false,
          },
        },
      ],
    },
    {
      path: "/system/equipment",
      name: "通道配置",
      component: () => import("@/views/equipment/List"),
      meta: {
        code: "RS-M04",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/device.png"),
      },
    },
    {
      path: "/system/merchandise",
      name: "商品信息",
      component: () => import("@/views/system/merchandise/List"),
      meta: {
        code: "HOTEL#RS-M05",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/goods.png"),
      },
    },
    {
      path: "/system/merchandise",
      name: "商品信息",
      component: () => import("@/views/system/merchandise/List"),
      meta: {
        code: "RETAIL#RS-M05",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/goods.png"),
      },
    },
    {
      path: "/system/customer",
      name: "购方信息维护",
      component: () => import("@/views/system/customer/List"),
      meta: {
        code: "RS-M08",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/customer.png"),
      },
    },
    // {
    //   path: "/system/label",
    //   name: "标签管理",
    //   component: () => import("@/views/system/label/Label"),
    //   meta: {
    //     code: "RS-M12",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/label.png"),
    //   },
    // },
    // {
    //   path: "/system/mail",
    //   name: "邮件模板",
    //   component: () => import("@/views/system/mail/List"),
    //   meta: {
    //     code: "RS-M09",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/emailsetting.png"),
    //   },
    // },
    {
      path: "/system/event",
      name: "任务管理",
      component: () => import("@/views/system/event/List"),
      meta: {
        code: "RS-M10",
        disabled: false,
        menu: true,
      },
    },
    {
      path: "/system/email",
      name: "发件邮箱",
      component: () => import("@/views/system/email/Email"),
      meta: {
        code: "RS-M06",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/postemail.png"),
      },
    },
    {
      path: "/system/name",
      name: "名称设置",
      component: () => import("@/views/system/name/Name"),
      meta: {
        code: "RS-M14",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/setting.png"),
      },
    },
    // {
    //   path: "/system/paas/info",
    //   name: "开票通道管理",
    //   component: () => import("@/views/paas-info/OrgPaasInfo"),
    //   meta: {
    //     code: "RS-M13",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/invoicemethod.png"),
    //   },
    // },
    // {
    //   path: "/system/middle-kind",
    //   name: "中类管理",
    //   component: () => import("@/views/system/middle-kind/MiddleKind"),
    //   meta: {
    //     code: "RS-M07",
    //     disabled: false,
    //     menu: true,
    //     icon: require("@/assets/navMenu/goodssetting.png"),
    //   },
    // },
    {
      path: "/logs/oper",
      name: "操作日志",
      component: () => import("@/views/logs-manage/oper"),
      meta: {
        code: "LOGS-OPER",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/log.png"),
      },
    },
    {
      path: "/logs/login",
      name: "登录日志",
      component: () => import("@/views/logs-manage/login"),
      meta: {
        code: "LOGS-LOGIN",
        disabled: false,
        menu: true,
        icon: require("@/assets/navMenu/loginlog.png"),
      },
    },
  ],
};


// 涉税项目
const taxProjects = {
  path: "/taxprojects",
  name: "涉税项目",
  component: () => import("@/views/TaxProjectManage"),
  meta: {
    code: "TP",
    disabled: true,

    menu: true,
  },
  children: [
    {
      path: "/taxprojects/project/list",
      name: "项目信息",
      disabled: false,
      component: () => import("@/views/taxprojects/project/list"),
      meta: { code: "TP-M01", menu: true ,icon: require("@/assets/navMenu/apply.png"),},
      children: [
        {
          path: "/taxprojects/project/edit",
          name: "项目信息编辑",
          component: () => import("@/views/taxprojects/project/edit"),
          meta: { code: "TP-M01" },
        },
        {
          path: "/taxprojects/project/detail",
          name: "项目信息详情",
          component: () => import("@/views/taxprojects/project/detail"),
          meta: { code: "TP-M01" },
        },
      ],
    },
    {
      path: "/taxprojects/contract/list",
      name: "合同信息",
      disabled: false,
      component: () => import("@/views/taxprojects/contract/list"),
      meta: { code: "TP-M02", menu: true,icon: require("@/assets/navMenu/data.png"), },
      children: [
        {
          path: "/taxprojects/contract/edit",
          name: "合同信息编辑",
          component: () => import("@/views/taxprojects/contract/edit"),
          meta: { code: "TP-M02" },
        },
        {
          path: "/taxprojects/contract/detail",
          name: "合同信息详情",
          component: () => import("@/views/taxprojects/contract/detail"),
          meta: { code: "TP-M02" },
        },
      ],
    },
    {
      path: "/taxprojects/prepayment/list",
      name: "预交信息",
      disabled: false,
      component: () => import("@/views/taxprojects/prepayment/list"),
      meta: { code: "TP-M03", menu: true,icon: require("@/assets/navMenu/data.png"), },
      children: [
        // {
        //   path: "/taxprojects/prepayment/edit",
        //   name: "预交信息编辑",
        //   component: () => import("@/views/taxprojects/prepayment/edit"),
        //   meta: { code: "TP-M03" },
        // },
        // {
        //   path: "/taxprojects/prepayment/detail",
        //   name: "预交信息详情",
        //   component: () => import("@/views/taxprojects/prepayment/detail"),
        //   meta: { code: "TP-M03" },
        // },
      ],
    }
  ],
};

// 报表中心
const easyReport = {
  path: "/easyReport",
  name: "报表中心",
  component: () => import("@/views/EasyReport"),
  meta: {
    code: "ER",
    disabled: true,

    menu: true,
  },
  children: [
    {
      path: "/easyReport/list",
      name: "主报表开发",
      disabled: false,
      component: () => import("@/views/easyReport/list"),
      meta: { code: "ER-M01", menu: true ,icon: require("@/assets/navMenu/data.png"),},
      children: [
        {
          path: "/taxprojects/project/edit",
          name: "主报表编辑",
          component: () => import("@/views/taxprojects/project/edit"),
          meta: { code: "ER-M01" },
        },
      ],
    },
    {
      path: "/easyReport/easyReportDisplay",
      name: "报表展现",
      disabled: false,
      component: () => import("@/views/easyReport/easyReportDisplay/easyReportDisplay"),
      meta: { code: "ER-M01", menu: true ,icon: require("@/assets/navMenu/data.png"),},
      children: [
      ],
    },
    {
      path: "/easyReport/list",
      name: "明细报表开发",
      disabled: false,
      component: () => import("@/views/easyReport/list"),
      meta: { code: "ER-M02", menu: true,icon: require("@/assets/navMenu/data.png"), },
      children: [
        {
          path: "/taxprojects/contract/edit",
          name: "明细报表编辑",
          component: () => import("@/views/taxprojects/contract/edit"),
          meta: { code: "ER-M02" },
        },
        {
          path: "/taxprojects/contract/detail",
          name: "明细报表详情",
          component: () => import("@/views/taxprojects/contract/detail"),
          meta: { code: "ER-M02" },
        },
      ],
    }
  ],
};

// 杂项 （个人中心：RS）
// const sundry = {
//   path: "/personalCenter",
//   name: "个人中心",
//   component: () => import("@/views/personalCenter/PersonalCenter"),
//   meta: {
//     code: "RH",
//   },
// };

// 基础路由
export const baseRoutes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/entry/pdt/Login"),
    name: "login",
  },
  {
    path: "/403",
    component: () => import("@/views/NotRoles"),
    name: "403",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFound"),
  },
  {
    path: "/reimburse-colInvoice",
    name: "reimburseColInvoice",
    component: () => import("@/views/ReimburseColInvoice"),
  },
];

// 日志管理
// export const logsManage = {
//   path: "/logsManage",
//   name: "日志管理",
//   component: () => import("@/views/LogsManage"),
//   meta: {
//     code: "Logs",
//     menu: true,
//     disabled: true,

//   },
//   children: [
//     {
//       path: "/logs/oper",
//       name: "操作日志",
//       component: () => import("@/views/logs-manage/oper"),
//       meta: {
//         code: "LOGS-OPER",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/log.png"),
//       },
//     },
//     {
//       path: "/logs/login",
//       name: "登录日志",
//       component: () => import("@/views/logs-manage/login"),
//       meta: {
//         code: "LOGS-LOGIN",
//         disabled: false,
//         menu: true,
//         icon: require("@/assets/navMenu/loginlog.png"),
//       },
//     },
//   ],
// };

// 路由列表导出
export const syncRoutes = [
  // personWalt, 
  invoiceOpen,
  // electron,
  invoiceCenter,
  // deduction,
  // entry,
  // invoicePool,
  voucherManage,
  // cooSal,
  // cooBuy,
  // supplierCooperate,
  // retailerCooperate,
  // declare,
  system,
  taxProjects,
  easyReport,
  // sundry,
  // logsManage,
];
